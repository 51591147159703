
  export default ({
    name: "ScheduleResume",
    data() {
      return {
        appointment: {
          day:"",
          time:"",
          date_id:"",
          agency: "TOYOTA HERMOSILLO",
          agency_id:"",
          apellidos:"",
          email:"",
          fecha:"",
          nombre:"",
          phone:"",
          tipo:"3",
          vehicle_client:"",
          formatted_date:"Selecciona una fecha y un horario",
        },
        vehicle: {
            vehiculo: "Audi Q5 3.0 V6 SQ5 Tiptronic At 2019",
            kms: "33600",
            year: "2020",
            brand: "Hyundai",
            model: "Tucson",
            version: "GLS",
        },
        customer: {
            Names: "Luis Ramos",
            Surnames: "Luis Ramos",
            Phone: "1234567811",
            Email: "mail@mail.com",
            Address: "Gomez Cuervo 31",
            Colony: "La Perla",
            Location: "Guadalajara, Jalisco",
            City:"",
            CityId:"",
            State:"",
            StateId:"",
            CP:"",
            Between:"",
            OutSideNumber:"",
            InsideNumber:"",
        },
        agency: {
          address: "Direccion",
          city: "Hermosillo",
          colonia: "Colonia 1",
          cp: "11111",
          email: "tes2s@gmg.com",
          id: 6,
          id_state: 26,
          latitude: "19.39271801953999",
          longitude: "-99.12452365006473",
          name: "TOYOTA HERMOSILLO",
          phone: "1111111111",
          state: "Sonora",
          url_whatsapp: "jhasdkjhas kashdk",
        },
      };
    },
    props: {
      title: null,
      data:null,
    },
    methods: {},
  });

