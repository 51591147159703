
import { defineComponent, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { DatePicker } from "v-calendar";
import ScheduleResume from "@/views/client/components/ScheduleResume.vue";
import { kmFormat } from "../../helpers/globalFuntions";

export default defineComponent({
  name: "Schedule",
  components: {
    ScheduleResume,
    DatePicker,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const resetBtn = ref<any>(null);
    const ModalRef = ref<null | HTMLElement>(null);
    const states = ref<any[]>([]);
    const cities = ref<any[]>([]);
    const showLoader = ref(false);
    const day = ref<any>([]);
    const hoursDate = ref("");
    const tituloHeader = ref("");

    const vehicle = ref({
      id: "",
      brand: "",
      model: "",
      version: "",
      year: "",
      transmission: "",
      Mileage: "",
    });

    const customer = ref({
      Email: "",
      Names: "",
      Phone: "",
      Surnames: "",
      City: "",
      Address: "",
    });

    const extra_data = ref({
      has_agency: "",
      has_appraiser: "",
      interested_vehicle_id: "",
    });

    const agency = ref({
      id: 1,
      name: "Grupo Solana",
      id_state: 26,
      city: "Hermosillo",
      address: "Opalo",
      colonia: "Colonia 1",
      cp: "34434",
      state: "Sonora",
      url_whatsapp: "wa.link/mzrjt5",
      phone: "2323324334",
      email: "Solana@grupo.com",
      latitude: "19.45427274236404",
      longitude: "-99.13482926287841",
    });

    const appointment = ref({
      tipo: 1,
      fecha: "",
      nombre: "",
      apellidos: "",
      email: "",
      phone: "",
      vehicle_client: "",
      agency: "",
      agency_id: "",
    });

    const schedule = ref({
      appointment: {
        day: "",
        time: "",
        date_id: "",
        agency: "TOYOTA HERMOSILLO",
        agency_id: "",
        apellidos: "",
        email: "",
        fecha: "",
        nombre: "",
        phone: "",
        tipo: "",
        schedule: "",
        vehicle_client: "",
        formatted_date: "Selecciona una fecha y un horario",
      },
      vehicle: {
        vehiculo: "",
        kms: "",
        year: "",
        brand: "",
        model: "",
        version: "",
        transmission: "",
        Mileage:"",
      },
      customer: {
        Names: "",
        Surnames: "",
        Phone: "",
        Email: "",
        Address: "",
        Colony: "",
        City: "",
        idCity: "",
        State: "",
        idState: "",
        Location: "",
        CP: "",
        Between: "",
        OutSideNumber: "",
        InsideNumber: "",
      },
      agency: {
        address: "",
        city: "",
        colonia: "",
        cp: "",
        email: "",
        id: "",
        id_state: "",
        latitude: "",
        longitude: "",
        name: "",
        phone: "",
        state: "",
        url_whatsapp: "",
      },
    });

    const calendarDates = ref({});
    const dates = ref([{}]);
    const times = ref([]);

    const getAgencyCalendar = async (type, agency_id, city = "") => {
      tituloHeader.value = type == 3 ? "Cita a domicilio": "Cita a agencia";

      try {
        let date = new Date();
        let start_day = date.getDate();
        let start_month = date.getMonth() + 1;
        let start_year = date.getFullYear();

        let end_day = 1;
        let end_month = start_month + 6;
        let end_year = end_month > 12 ? start_year + 1 : start_year;
        end_month > 12 ? (end_month = 1) : false;

        let start_date = `${start_year}-${start_month}-1`;
        let end_date = `${end_year}-${end_month}-1`;

        let body_data = { fecha_inicial: start_date, fecha_final: end_date };
        const { data } =
          type == 3
            ? await axios.post(
                process.env.VUE_APP_API_URL + `/api/citas/calendar-city/${type}/${city}`,
                body_data
              )
            : await axios.post(
                process.env.VUE_APP_API_URL + `/api/citas/calendar/${type}/${agency_id}`,
                body_data
              );

        // console.log("start_day ", date.getDate());

        // console.log(type, agency_id, city);
        // const { data } = await axios.post(process.env.VUE_APP_API_URL + `/api/citas/calendar/1/1`);
        // const { data } = await axios.post(process.env.VUE_APP_API_URL + `/api/citas/calendar-city/3/Ciudad de Mexico`);

        if (data) {
          dates.value = data.data;
          calendarDates.value = Object.keys(data.data).map((key) => {
            let date = key.split("-");
            // times.value.length == 0 ? (times.value = data.data[key]) : false;
            times.value = [];
            if (parseInt(date[2]) > start_day || parseInt(date[1]) > start_month) {
              return {
                start: new Date(
                  parseInt(date[0]),
                  parseInt(date[1]) - 1,
                  parseInt(date[2])
                ),
                end: new Date(
                  parseInt(date[0]),
                  parseInt(date[1]) - 1,
                  parseInt(date[2])
                ),
              };
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getStates = async () => {
      showLoader.value = true;

      try {
        const { data } = await ApiService.get("/api/states");
        states.value = data.data.state;
      } catch (error) {
        console.log(error);
      }

      showLoader.value = false;
    };

    const returned = () => {
      router.go(-1);
    };

    const getCities = async () => {
      showLoader.value = true;

      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            `/api/cities-by-state/${schedule.value.customer.idState}`
        );
        cities.value = data.data;
      } catch (error) {
        console.log(error);
      }
      showLoader.value = false;
    };

    const setCity = async () => {
      for (let key in cities) {
        if (cities[key].id == schedule.value.customer.idCity) {
          schedule.value.customer.City = cities[key].name;
        }
      }
    };

    const setDay = (data) => {
      hoursDate.value = "";
      let day = data.date.getDate();
      let month = data.date.getMonth() + 1;
      let year = data.date.getFullYear();

      day < 10 ? (day = `0${day}`) : false;
      month < 10 ? (month = `0${month}`) : false;

      schedule.value.appointment.day = day;
      schedule.value.appointment.time = "";
      schedule.value.appointment.date_id = "";
      schedule.value.appointment.formatted_date = "Escoge un horario para este día";
      //@Todo unchecked all radio
      times.value = dates.value[`${year}-${month}-${day}`];
    };

    const setTime = (data) => {
      schedule.value.appointment.time = data.hora;
      schedule.value.appointment.date_id = data.id;
      let formato = `${data.formatted}`;
      schedule.value.appointment.formatted_date = formato.charAt(0).toUpperCase() + formato.slice(1).replace('º', '');
    };

    const confirmSave = async () => {
      if (schedule.value.appointment.day == "") {
        Swal.fire({
          text: "Selecciona un día para tu cita",
          icon: "warning",
          position: "center",
        });

        // console.log("Day ", schedule.value.appointment.day);

        return false;
      }

      if (schedule.value.appointment.time == "") {
        Swal.fire({
          text: "Selecciona un horario para tu cita",
          icon: "warning",
          position: "center",
        });

        // console.log("Time ", schedule.value.appointment.time);

        return false;
      }

      if (schedule.value.appointment.date_id == "") {
        Swal.fire({
          text:
            "Algo salio mal con el registro, intenta seleccionar un día y un horario para tu cita",
          icon: "warning",
          position: "center",
        });

        // console.log("date_id ", schedule.value.appointment.time);

        return false;
      }

      if (schedule.value.appointment.tipo == "3") {
        if (schedule.value.customer.idState == "") {
          Swal.fire({
            text: "Selecciona un estado",
            icon: "warning",
            position: "center",
          });

          // console.log("State ", schedule.value.customer.idState);

          return false;
        }

        if (schedule.value.customer.idCity == "") {
          Swal.fire({
            text: "Agrega una dirección y selecciona un ciudad",
            icon: "warning",
            position: "center",
          });

          // console.log("City ", schedule.value.customer.idState);

          return false;
        }

        if (schedule.value.customer.CP == "") {
          Swal.fire({
            text: "Ingresa un código postal",
            icon: "warning",
            position: "center",
          });

          // console.log("CP ", schedule.value.customer.CP);

          return false;
        }

        if (schedule.value.customer.CP.length != 5) {
          Swal.fire({
            text: "El código postal es incorrecto, debe cumplir con 5 digitos",
            icon: "warning",
            position: "center",
          });

          // console.log("CP ", schedule.value.customer.CP);

          return false;
        }

        if (schedule.value.customer.Address == "") {
          Swal.fire({
            text: "Ingresa una dirección",
            icon: "warning",
            position: "center",
          });

          // console.log("Address ", schedule.value.customer.Address);

          return false;
        }

        if (schedule.value.customer.OutSideNumber == "") {
          Swal.fire({
            text: "Ingresa un número exterior",
            icon: "warning",
            position: "center",
          });

          // console.log("OutSideNumber ", schedule.value.customer.OutSideNumber);

          return false;
        }

        if (schedule.value.customer.Colony == "") {
          Swal.fire({ icon: "warning", position: "center", text: "Ingresa una colonia" });

          // console.log("Colony ", schedule.value.customer.Colony);

          return false;
        }
      }

      save();
    };

    const saveCustomerAddress = async () => {
      hideModal(ModalRef.value);
    };

    const validationSchema = Yup.object().shape({
      zip_code: Yup.string()
        .notOneOf(["1"], "Código postal es obligatorio")
        .matches(/^[0-9]{5}$/, "Código postal es obligatorio de 5 digitos")
        .required("Código postal es obligatorio")
        .label("Código postal"),
      state_id: Yup.string()
        .notOneOf(["1"], "El estado es obligatorio")
        .required("El estado es obligatorio")
        .label("Estado"),
      city_id: Yup.string()
        .notOneOf(["1"], "La ciudad es obligatoria")
        .required("La ciudad es obligatoria")
        .label("Ciudad"),
      address: Yup.string()
        .notOneOf(["1"], "La dirección es obligatoria")
        .required("La dirección es obligatoria")
        .label("Dirección"),
      outside_number: Yup.string()
        .notOneOf(["1"], "El número exterior es obligatorio")
        .required("El número exterior es obligatorio")
        .label("Número exterior"),
    });

    const save = async () => {
      let save_data = {};

      //Cita en agencia o venta
      if (
        schedule.value.appointment.tipo == "1" ||
        schedule.value.appointment.tipo == "2"
      ) {
        save_data = {
          tipo: parseInt(schedule.value.appointment.tipo),
          fecha: schedule.value.appointment.date_id,
          nombre: schedule.value.customer.Names,
          apellidos: schedule.value.customer.Surnames,
          email: schedule.value.customer.Email,
          phone: schedule.value.customer.Phone,
          vehicle_client: parseInt(vehicle.value.id),
          agency: parseInt(schedule.value.appointment.agency_id),
          vehicle: extra_data.value.interested_vehicle_id,
        };

        //Cita a domicilio
      } else if (schedule.value.appointment.tipo == "3") {
        save_data = {
          tipo: parseInt(schedule.value.appointment.tipo),
          fecha: schedule.value.appointment.date_id,
          nombre: schedule.value.customer.Names,
          apellidos: schedule.value.customer.Surnames,
          email: schedule.value.customer.Email,
          phone: schedule.value.customer.Phone,
          avaluo: parseInt(vehicle.value.id),
          cp: schedule.value.customer.CP,
          estado: parseInt(schedule.value.customer.idState),
          ciudad: schedule.value.customer.City,
          direccion: schedule.value.customer.Address,
          num_ext: schedule.value.customer.OutSideNumber,
          num_int: schedule.value.customer.InsideNumber || "S/N",
          entre_calles: schedule.value.customer.Between,
          colonia: schedule.value.customer.Colony,
          vehicle: extra_data.value.interested_vehicle_id,
        };
      }

      //@Todo Resolver cuando el usuario escoge una fecha y de pronto ya no hay valuadores
      // volver a cargar las fechas y bloquear las ocupadas
      await axios
        .post(process.env.VUE_APP_API_URL + "/api/site/citas", save_data)
        .then((response) => {
          // console.log(response.data.data.idcita);

          if (response.data.success) {
            localStorage.removeItem("extra_data");
            localStorage.setItem(
              "appointment_schedule",
              JSON.stringify(response.data.data)
            );

            router.push("/confirmar-cita/" + response.data.data.idcita);
          } else {
            Swal.fire({
              icon: "error",
              position: "center",
              showConfirmButton: true,
              title: "Hubo un error en el servidor", // response.data.message,
              text: response.data.message,
            });
          }
        })
        .catch((error) => {
          // console.log("Response data", error.response.data);

          if (error.response) {
            let message =
              error.response.data.message &&
              !error.response.data.message.includes("The given data was invalid.")
                ? error.response.data.message
                : "";

            if (error.response.data.errors != undefined) {
              let errors = Object.entries(error.response.data.errors);

              errors.forEach((value, index) => {
                message += value[1] + " <br>";
              });
            }

            // console.log("Entro aqui", error);

            Swal.fire({
              icon: "error",
              title: message,
              position: "center",
              showConfirmButton: true,
            });
          } else {
            console.log("error", error);
          }
        });
    };

    onBeforeMount(async () => {
      vehicle.value = JSON.parse(localStorage.getItem("appointment_vehicle") || "{}");
      console.log(vehicle.value);
      appointment.value = JSON.parse(localStorage.getItem("appointment_data") || "{}");
      customer.value = JSON.parse(localStorage.getItem("appointment_customer") || "{}");
      agency.value = JSON.parse(localStorage.getItem("appointment_agency") || "{}");
      extra_data.value = JSON.parse(localStorage.getItem("extra_data") || "{}");

      for (let key in customer.value) {
        schedule.value.customer[key] = customer.value[key];
      }

      for (let key in agency.value) {
        schedule.value.agency[key] = agency.value[key];
      }

      for (let key in vehicle.value) {
        schedule.value.vehicle[key] = vehicle.value[key];
      }

      for (let key in appointment.value) {
        schedule.value.appointment[key] = appointment.value[key];
      }

      for (let key in extra_data.value) {
        extra_data.value[key] = extra_data.value[key];
      }

      // console.log("agency ", agency.value);

      if (route.params.type != undefined && route.params.type == "en-domicilio") {
        localStorage.removeItem("appointment_data");
        schedule.value.appointment.tipo = "3";

        let appointment_data = {};
        for (let key in schedule.value.appointment) {
          appointment_data[key] = schedule.value.appointment[key];
        }

        localStorage.setItem("appointment_data", JSON.stringify(appointment_data));

        await getStates();
        await getCities();
      }

      await getAgencyCalendar(
        schedule.value.appointment.tipo,
        appointment.value.agency_id,
        schedule.value.customer.City
      );
    });
    
    return {
      times,
      agency,
      vehicle,
      customer,
      schedule,
      appointment,
      confirmSave,
      save,
      getStates,
      getCities,
      states,
      cities,
      setCity,
      calendarDates,
      getAgencyCalendar,
      setDay,
      setTime,
      extra_data,
      returned,
      day,
      hoursDate,
      kmFormat,
      validationSchema,
      tituloHeader
    };
  },
});
